.identity{
    &_empty{
        max-width: 600px;
        margin: 40px auto 30px;
        display: block;
        text-align: center;
        img{
            width: 250px;
        }
        h4{
            margin: 15px 0;
        }
        p{
            span{
                color: $color-primary;
                cursor: pointer;
            }
        }
        button{
            background-color: $color-sidebar;
            font-size: 1em;
            padding: 3px 10px;
            color: white;
            border: 1px solid transparent;
            border-radius: 4px;
            text-align: left;
            img{
                margin: -3px 5px 0 0;
                width: 15px;
            }

        }
    }

    &_details{
        border: 1px solid $color-primary;
        margin: 30px 0;
        border-radius: 15px 0 15px 0;
        @media(min-width: $tab-port) {
            display: flex;
            max-width: 800px;
        }

        &-section1{
            font-weight: 600;
            @media(min-width: $tab-port) {
                border-right: 1px solid $color-primary;
                width: 50%;
            }

            .key{
                color: #828282;
            }

            .numberKey{
                color: #828282;
                margin: 0;
            }

            .value{
                color: #0F123F;
                margin-left: auto;

                @media(max-width: 585px) {
                    flex: 1
                }
            }

            .top-Text{
                margin: 0 !important;
            }

            &-top{
                padding: 15px;

                .column{
                    @media(max-width: 585px) {
                        flex-direction: column;
                    }
                }

                .flex{
                    display: flex;

                    p{
                        text-align: right;
                    }
                    .phoneNumber{
                        margin-bottom: 10px;

                        @media(max-width: 585px) {
                            flex: 0.5
                        }

                        .submit{
                            margin: 10px 0;
                            background: #0046e6;
                            color: #fff;
                            padding: 1px;
                            border-radius: 3px;
                            height: 20px;
                        }

                        .inputBox{
                            p{
                                text-align: left;
                            }
                        }
                    }

                    button{
                        background: #fff;
                        border: 2px solid #0046e6;
                        border-radius: 5px;
                        padding: 3px;
                        margin-bottom: 5px;
                        font-size: 15px;
                        font-weight: 500;
                        height: 20px;
                    }
                }
            }
        }
    }

    &_bank{
        &-top{
            position: relative;
        }
    }

    &_result{
        box-sizing: border-box;
        
        &-top{
            background: #EEF3FF;
            border-radius: 15px;
            display: flex;
            align-items: center;
            padding: 10px 40px;
            @media(max-width: $tab-port){
                padding: 10px 20px;
                align-items: flex-start;
                margin-top: 60px;
            }
            @media(max-width: $mobile){
                padding: 20px 10px;
            }

            &_image{
                width: 130px;
                background-color: white;
                height: 130px;
                border: 2px solid white;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 20px;
                flex-shrink: 0;

                @media(max-width: $tab-port){
                    width: 60px;
                    flex-shrink: 0;
                    height: 60px;
                }
                img{
                    width: 100%;
                    height: auto;
                }
                h1{
                    margin-top: 30px;
                    font-size: 3.5em;
                    text-align: center;
                    @media(max-width: $tab-port){
                        margin-top: 10px;
                        font-size: 2em;
                    }
                }
            }
            h5{
                color: #101828;
                font-size: 1.7em;
                @media(max-width: $mobile){
                    font-size: 1.4em;
                }
            }
            p{
                color: #667085;
                font-weight: 700;
            }
            &_button{
                margin-left: auto;
                width: 35px;
                @media(max-width: $mobile){
                    display: none;
                }
            }
        }

        &-box1{
            padding: 5px 10px;
            margin-top: 10px;

            .text1{
                color: #667085;
                margin: 0;
            }
            .text2{
                color: #344054;
            }
            .text3{
                color: #427CFF;
                text-transform: lowercase;
            }
            
            .phoneNumber{
                margin-top: 10px;
                
                button{
                    background: #fff;
                    border: 2px solid #0046e6;
                    border-radius: 5px;
                    padding: 3px;
                    margin-bottom: 15px;
                    font-size: 15px;
                    font-weight: 500;
                    // height: 20px;
                }
                .submit{
                    margin: 10px 0;
                    background: #0046e6;
                    color: #fff;
                    padding: 3px 15px;
                    border-radius: 3px;
                    // height: 20px;
                }

                .inputBox{
                    border: 1px solid #828282;
                    padding: 5px 10px;
                    margin: -10px 0 0;
                    border-radius: 4px;
                    p{
                        text-align: left;
                    }
                }
            }
        }

        &-tableBox{
            overflow-x: auto;
        }

        &-table{
            // overflow-x: scroll;
            width: 1800px;
            text-transform: capitalize;

            thead{
                background-color: #F0F0F0;
                font-size: 1em;
                border-radius: 5px;

                th{
                    color: #667085;
                    padding: 5px 7px;
                    font-weight: 400;
                }
            }

            tbody{
                font-size: 1em;

                td{
                    padding: 5px 7px;
                }
            }
        }
    }
}