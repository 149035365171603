.income{
    &_actions{
        margin: 40px;
        @media(min-width: $tab-port){
            // display: flex;
        }

        &-box{
            border: 0.25px solid $color-sidebar;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
            width: 300px;
            padding: 15px;
            text-align: center;
            margin: 0 40px 0 0;

            @media(max-width:$tab-port){
                width: 100%;
                margin-bottom: 30px;
            }
        }
        &-radar{
            border: 0.25px solid $color-sidebar;
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
            width: 300px;
            padding: 15px;
            margin: 0 40px 0 0;

            @media(max-width:$tab-port){
                width: 100%;
                margin-bottom: 30px;
            }

            .comingSoon{
                display: flex;
                p{
                    color: white;
                    background:#FFC702;
                    font-size: 0.75em;
                    border-radius: 5px;
                    height: 1.4em;
                    padding: 0px 2px !important;
                    margin: 12px 0 0 7px;
                }
            }
            
            input{
                width: 100%;
                margin: 10px 0 20px;
                padding: 5px 15px;
                height: 40px;
                border: 2px solid #C4C4C4;
                border-radius: 5px;
                color: $color-formInput;
            }

            button{
                width: 100%;
            }
        }
        h4{
            font-size: 1.2em;
            margin: 10px 0 !important;
        }
        button{
            border: 1px solid $color-sidebar;
            border-radius: 8px;
            background-color: $color-sidebar;
            padding: 8px 15px;
            color: white;
            margin: 0 0 15px;
            text-align: center;
            font-weight: 500;
        }
    }

    &_radarNumber{
        position: relative;

        h4{
            font-size: 1.2em;
            margin: 10px 0 !important;
        }
        button{
            border: 1px solid $color-sidebar;
            border-radius: 8px;
            background-color: $color-sidebar;
            padding: 8px 15px;
            color: white;
            margin: 0 0 15px;
            text-align: center;
            font-weight: 500;
        }

        &-cancel{
            position: absolute;
            top: -30px;
            left: 0;
        }

        &-form{
            box-sizing: border-box;
            box-shadow: 6px 4px 38px 4px rgba(0, 0, 0, 0.02);
            border-radius: 4px;
            padding: 15px;
        

            @media(max-width:$tab-port){
                width: 100%;
                margin-bottom: 30px;
            }

            .comingSoon{
                display: flex;
                p{
                    color: white;
                    background:#FFC702;
                    font-size: 0.75em;
                    border-radius: 5px;
                    height: 1.4em;
                    padding: 0px 2px !important;
                    margin: 12px 0 0 7px;
                }
            }
            
            input{
                width: 100%;
                margin: 10px 0 20px;
                padding: 5px 15px;
                height: 40px;
                border: 2px solid #C4C4C4;
                border-radius: 5px;
                color: $color-formInput;
            }

            button{
                width: 100%;
            }
        }
    }

    &_cards{
        &-front{
            width: 400px;
            height: 220px;
            color: white;
            border-radius: 15px;
            padding: 20px;

            &-logo{
                width: 50px;
                height: 50px;
                overflow: hidden;
                border-radius: 50%;
                img{
                    width: 100%;
                }
            }

            &-text{
                margin: 0px 10px;
                p{
                    margin: 0 !important;
                    font-size: 0.9em;
                }
                h4{
                    margin: 0 !important;
                    font-size: 1.3em;
                }
            }
        }
    }

    &_keywords{
        display: flex;
        align-items: flex-start;

        &-info{
            position: relative;
            margin: -5px 5px 0 0;

            img{
                cursor: pointer;
            }

            &-content{
                padding: 15px;
                background: white;
                z-index: 2;
                width: 300px;
                font-size: 0.9em;
                text-align: justify;
                right: 15px;
                border-radius: 15px 0 15px 15px;
                position: absolute;
                border: 1px solid #F2F4F7;
                box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

                p{
                    margin: 0;
                }
            }
        }
    }

    &_editFilter{
        display: flex;
        button{
            padding: 7px 10px;
            background: white;
            border: 1px solid #D0D5DD;
            color: #344054;
            border-radius: 6px;
            margin-right: 10px;
        }

        .btnInactive{
            color: white;
            border: 1px solid $color-formInput;
            background: $color-formInput !important;
        }

        p{
            font-size: 0.8em;
            margin: 0;
        }

        &_filters{
            background: #EFF8FF;
            border-radius: 10px;
            color: $color-primary;
            padding: 1px 7px;
            display: flex; 
            align-items: center;
            .dot{
                background: $color-primary; 
                width: 7px; 
                height: 7px; 
                border-radius: 50%; 
                margin-right: 3px;
            }

            &-query{
                color: black; 
                text-transform: uppercase; 
                font-weight: bold;
                margin: 0 5px;
            }
        }
    }

    &_spending{
        &-box{
            border: 1px solid #D0D5DD;
            padding: 20px;
            width: 500px;
            margin: 30px 0;
            border-radius: 10px;
            box-sizing: border-box;

            &-filter{
                &-options{
                    z-index: 2;
                    background: white;
                    max-height: 280px;
                    border: 1px solid #EAECF0;
                    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                    ::-webkit-scrollbar {
                        width: 10px;
                    }
                    ::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px white; 
                    border-radius: 10px;
                    }
                    ::-webkit-scrollbar-thumb {
                        background: #EEEFF0; 
                        border-radius: 10px;
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: #EEEFF0; 
                    }
                    
                    p{
                        margin: 5px 10px;
                        cursor: pointer;
                        white-space: nowrap;
                    }
                    hr{
                        margin: 0;
                        border: 1px solid #EAECF0;
                    }
                }

                &-monthRange{
                    padding: 20px;
                }

                &-months{
                    justify-content: center;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 10px 0 -10px;
                    p{
                        width: 33%;
                        margin: 5px 0;
                        padding: 5px 0;
                        text-align: center;
                        font-weight: 600;
                        font-size: 0.95em;
                        cursor: pointer;
                    }

                    .first{
                        background: #1B60FC;
                        border-radius: 7px 0 0 7px;
                        color: white;
                    }

                    .middle{
                        background: #F2F4F7;
                    }

                    .last{
                        background: #1B60FC;
                        border-radius: 0 7px 7px 0;
                        color: white;
                    }
                }
            }

            &-data{
                display: flex;
                align-items: center;

                .rep{
                    margin-left: 40px;
                    div{
                        display: flex;
                        align-items: center;

                        p{
                            margin: 0;
                            text-transform: capitalize;
                        }

                        span{
                            width: 10px;
                            height: 10px;
                            margin-right: 5px;
                            border-radius: 50%;
                        }
                    }
                }
            }

            &-single{
                display: flex;
                z-index: 1;
                align-items: flex-start;
                img{
                    width: 40px;
                    margin-right: 5px;
                    flex-shrink: 0;
                }
                p{
                    font-size: 0.9em;
                    text-transform: capitalize;
                    span{
                        font-size: 1.2em;
                        color: #5C5C5C;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    
    &-filterActive{
        border: 1px solid white;
        background: rgb(27, 96, 252);

        &-text{
            color: white
        }
    }

    &_detailsNav{
        &-table{
            display: flex; 
            align-items: center;
            margin-top: 30px; 

            &-switch{
                width: 50px;
                margin-left: 30px;
                cursor: pointer;

                @media(max-width: $tab-port){
                    display: none;
                }
            }
        }
    }

    &_highlight{
        padding: 30px;
        margin: 30px 0;
        max-width: 800px;
        border: 1px solid #D0D5DD;
        border-radius: 10px;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(243, 246, 255, 0.08) 100%);
        
        &_top{
            border-radius: 8px 8px 0 0 ;
            border: 1px solid #EAECF0;
            padding: 15px 10px;
    
            h4{
                font-size: 1.3em;
                margin: 3px 0 0;
    
                img{
                    margin-right: 10px;
                    margin-top: -3px;
                }
            }
        }

        table{
            width: 100%;
            border: 1px solid #EAECF0;
            
            tr{
                border-bottom: 1px solid #DADADA;
            }

            th,td{
                border-bottom: 1px solid #EAECF0;
                padding: 15px 5px !important;
                margin: 0 5px;
                color: #667085;
            }

            th{
                color: #667085;
                font-weight: 400;
                background-color: #F9FAFB;
            }

            .first{
                color: black;
                font-weight: 500;
            }

            .error{
                color: #FF0000;
            }

            .good{
                color: #48D900;
            }
        }
    }
}