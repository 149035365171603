.table{
    box-sizing: border-box;
    width: 100%;
    padding: 15px 0px;
    text-transform: capitalize;
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 0 0 8px 8px;

    @media(max-width: 1400px){
        overflow-x: scroll;
    }

    &_top{
        border-radius: 8px 8px 0 0 ;
        border: 1px solid #EAECF0;
        padding: 20px 10px;
        margin-top: 30px;
        position: relative;

        h4{
            font-size: 1.3em;
            margin: 3px 0 0;

            img{
                margin-right: 10px;
                margin-top: -3px;
            }
        }
    }

    &_banks{
        position: relative;
        height: 60px;
        width: 120px;

        &-box{
            border-radius: 50%;
            width: 50px;
            height: 50px;
            position: absolute;
            border: 2px solid white;
            overflow: hidden;
            img{
                width: 100%;
                height: auto;
            }
        }

        .first{
            top:5px;
            left: 0;
            background: white;
        }
        .second{
            top:5px;
            left: 25px;
            background: white;
        }
        .others{
            top:5px;
            left: 50px;
            background-color: rgb(0,180,255);
            p{
                font-size: 1.4em;
                font-weight: 600;
                color: white;
                text-align: center;
                margin-top: 5px;
            }
        }
    }
    
    &_action{
        position: relative;
        height: 60px;
        width: 30px;
        &-contain{
            border-radius: 50%;
            width: 25px;
            height: 25px;
            cursor: pointer;
            overflow: hidden;
            background: #0046E6;
        }

        &-options{
            position: absolute;
            top: 45px;
            right: 5px;
            border-radius: 15px;
            background: white;
            z-index: 2;
            width: 80px;
            padding: 10px;
            box-shadow: 0px 15.1397px 161.94px rgba(37, 72, 153, 0.17);
            hr{
                margin: 10px 0;
            }
            p{
                cursor: pointer;
                margin: 0;
            }
        }
    }

    table{
        width: 100%;

        tr{
            border-bottom: 0.75px solid #EAECF0 !important;
            color: #0F123F;
            font-size: 1em;
        }
        th, td{
            padding: 10px 5px 10px 10px;
        }
        th{
            opacity: 0.56;
            font-weight: 400;
            text-transform: capitalize !important;
        }

        thead{
            border-bottom: 1px solid #EAECF0 !important;
        }
        tbody{
            border: 1px solid #EAECF0 !important;
        }
        
        .open{
            font-size: 0.9em;
            border: 1px solid rgba(196, 32, 92, 0.19);
            color: #D1373F;
            font-weight: 600;
            border-radius: 25px;
            background: rgba(196, 32, 92, 0.19);
            text-align: center;
            padding: 2px 10px;
            cursor: default;
            display: flex;
            align-items: center;

            div{
                background: #D1373F;
                width: 5px;
                margin-right: 4px;
                height: 5px;
                font-size: 2em;
                border-radius: 50%;
            }
        }

        .closed{
            font-size: 0.9em;
            border: 1px solid #ECFDF3;
            color: #027A48;
            font-weight: 600;
            cursor: default;
            border-radius: 25px;
            background: #ECFDF3;
            text-align: center;
            padding: 2px 10px;
            display: flex;
            align-items: center;

            div{
                background: #027A48;
                width: 5px;
                margin-right: 4px;
                height: 5px;
                font-size: 2em;
                border-radius: 50%;
            }
        }

        .inProgress{
            font-size: 0.9em;
            border: 1px solid #ECFDF3;
            color: #D88303;
            font-weight: 600;
            cursor: default;
            border-radius: 25px;
            background: #FEE4BE;
            text-align: center;
            padding: 2px 10px;
            display: flex;
            align-items: center;

            div{
                background: #FEE4BE;;
                width: 5px;
                margin-right: 4px;
                height: 5px;
                font-size: 2em;
                border-radius: 50%;
            }
        }
        .deliquent{
            font-size: 0.9em;
            border: 1px solid #ECFDF3;
            color: #fbf5f6;
            font-weight: 600;
            cursor: default;
            border-radius: 25px;
            background: #D1373F;
            text-align: center;
            padding: 2px 10px;
            display: flex;
            align-items: center;

            div{
                background: #FEE4BE;;
                width: 5px;
                margin-right: 4px;
                height: 5px;
                font-size: 2em;
                border-radius: 50%;
            }
        }

        .other{
            font-size: 0.9em;
            border: 1px solid #FFF3C8;
            color: #FF7B02;
            font-weight: 600;
            cursor: default;
            border-radius: 25px;
            background: #FFF3C8;
            text-align: center;
            padding: 2px 10px;
            display: flex;
            align-items: center;

            div{
                background: #FF7B02;
                width: 5px;
                margin-right: 4px;
                height: 5px;
                font-size: 2em;
                border-radius: 50%;
            }
        }
    }

    &_income{
        &-btn{
            button{
                font-size: 0.8em;
                padding: 3px 10px;
                color: white;
                border: 1px solid transparent;
                border-radius: 4px;
                text-align: left;
                img{
                    width: 20px;
                }
            }

            .view{
                background-color: #47D800;
                margin-right: 10px;
                width: 100px;
                img{
                    margin-bottom: 3px;
                    margin-right: 8px;
                }
            }
            .reload{
                background-color: $color-sidebar;
                width: 125px;
                margin-right: 10px;
                img{
                    margin-right: 5px;
                }
            }
            .share{
                background-color: $color-sidebar;
                img{
                    margin: -3px 5px 0 0;
                    width: 15px;
                }
            }
            .link{
                background-color: #47D800;
                margin-right: 10px;
                img{
                    margin-bottom: 3px;
                    margin-right: 8px;
                }
            }

            .inactive{
                background-color: $color-formInput !important;
            }
        }
    }

    &_audit{
        .green{
            color:#47D800;
        }
        .red{
            color: #FF2608;
        }
        &-btn{
            button{
                font-size: 0.9em;
                color: white;
                padding: 4px 10px;
                border-radius: 5px;
                text-transform: capitalize;
            }

            .success{
                background: #47D800;
                border: 1px solid #47D800;
            }

            .failed{
                background: #FF2608;
                border: 1px solid #FF2608;
            }
        }
    }
}